@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 48px;
  padding: 64px 80px 96px;
  @extend %flex-column;

  @media (max-width: $tablet-l) {
    padding: 32px 32px 48px;
  }

  @media (max-width: $tablet) {
    gap: 32px;
    padding: 32px 16px 48px;
  }

  &_head {
    width: 100%;
    margin: 0 auto;
    max-width: 768px;
    text-align: center;
    gap: 16px;
    @extend %flex-column-center;

    @media (max-width: $tablet) {
      text-align: left;
      align-items: flex-start;
    }

  }

}
