@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  display: flex;
  opacity: 0;
  transition: all 0.3s;
  justify-content: flex-end;
  background: #10182833;
  backdrop-filter: blur(1px);

  &_open {
    opacity: 1;

    .wrapper_container {
      transform: translate(0);
    }
  }

  &_container {
    width: 100%;
    transform: translate(100%);
    max-width: 493px;
    transition: 0.6s;
    background-color: $white;

    @media (max-width: $tablet-m) {
      max-width: unset;
    }
  }

  &_header {
    width: 100%;
    padding: 24px;
    background-color: $primary-500;
    border: 1px solid $primary-500;
    position: relative;
    @include svgFill($white);

    @media (max-width: $tablet-m) {
      padding: 13px 16px;
    }

    &_closable {
      padding-right: 56px;

      @media (max-width: $tablet-m) {
        padding-right: 48px;
      }
    }

    &_back {
      padding-left: 56px;

      @media (max-width: $tablet-m) {
        padding-left: 48px;
      }
    }
  }

  &_title {
    color: $white;
    word-wrap: break-word;
    @extend %subheading-1;

    @media (max-width: $tablet-m) {
      font-size: 16px;
      font-weight: 600;
      line-height: 22.4px;
    }

    &_center {
      text-align: center;
    }
  }

  &_close {
    gap: 8px;
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    @extend %flex-align;

    &_text {
      color: $white;
      cursor: pointer;
      border-bottom: 1px solid $white;
      @extend %micro-2;
    }

    @media (max-width: $tablet-m) {
      right: 16px;
    }
  }

  &_left_icon {
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: $tablet-m) {
      left: 16px;
    }
  }
}
