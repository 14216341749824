%secondary-1 {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
}

%secondary-2 {
  font-size: 16px;
  font-weight: 700;
  line-height: 100%;
}

%secondary-3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
}

%secondary-4 {
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
}

%secondary-5 {
  font-size: 18px;
  font-weight: 500;
  line-height: 25.2px;
}

%heading-1 {
  font-size: 110px;
  font-weight: 800;
  line-height: 100%;
}

%heading-2 {
  font-size: 60px;
  font-weight: 600;
  line-height: 75px;
}

%heading-3 {
  font-size: 42px;
  font-weight: 600;
  line-height: 58.8px;
}

%heading-4 {
  font-size: 48px;
  font-weight: 400;
  line-height: 100%;
}

%heading-5 {
  font-size: 41px;
  font-weight: 600;
  line-height: 41px;
}

%heading-6 {
  font-size: 30px;
  font-weight: 500;
  line-height: 42px;
}

%subheading-1 {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

%subheading-2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

%subheading-3 {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

%subheading-4 {
  font-size: 32px;
  font-weight: 500;
  line-height: 44.8px;
}

%subheading-5 {
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
}

%subheading-6 {
  font-size: 24px;
  font-weight: 500;
  line-height: 33.6px;
}

%micro-1 {
  font-size: 10px;
  font-weight: 500;
  line-height: 100%;
}

%micro-2 {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
}

%micro-3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
}

%micro-4 {
  font-weight: 500;
  font-size: 12px;
  line-height: 16.8px;
}

%micro-5 {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
}

%microSb-5 {
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
}

%microB-5 {
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
}
