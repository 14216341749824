@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  padding: 16px;
  position: fixed;
  min-height: 100dvh;
  background-color: rgba(26, 26, 26, 0.2);
  @extend %flex-center;

  &_close {
    right: 0;
    top: -44px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    position: absolute;
    border-radius: 50%;
    background-color: $white;
    @extend %flex-center;
    @include svgFill($gray-500, 20px);
  }

  &_block {
    width: 100%;
    padding: 24px;
    max-width: 600px;
    background: $white;
    border-radius: 16px;
    position: relative;
    border: 1px solid $gray-200;
    max-height: calc(100vh - 120px);
    box-shadow:
      0px 2px 4px -2px #1018280f,
      0px 4px 8px -2px #1018281a;
  }
}
