@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  width: 100%;
  position: relative;

  &_header {
    gap: 16px;
    cursor: pointer;
    padding: 16px 24px;
    margin-bottom: 8px;
    transition: all 0.3s;
    @extend %flex-align;
    @include svgFill($primary-500, 24px);

    &:hover {
      background-color: $gray-200;
    }
  }

  &_title {
    gap: 2px;
    color: $gray-900;
    @extend %secondary-3;
    @extend %flex-align;
    @include svgFill($gray-900, 20px);

    & > svg {
      transition: all 0.3s;
    }

    &_icon > svg {
      transform: rotate(-180deg);
    }
  }

  &_dropdown {
    top: 50px;
    left: 50px;
    position: absolute;
    @extend %flex-column-center;

    &_text {
      color: $white;
      cursor: pointer;
      @extend %secondary-3;

      &_active,
      &:hover {
        text-decoration: underline;
      }
    }

    &_container {
      gap: 4px;
      border-radius: 8px;
      position: relative;
      padding: 8px 12px;
      background-color: $gray-900;
      @extend %flex-column;

      &:before {
        content: ' ';
        position: absolute;
        background-color: #101828;
        width: 7px;
        height: 7px;
        top: 0;
        border-radius: 1px;
        left: 50%;
        z-index: 0;
        transform: rotate(45deg) translate(-50%);
      }
    }
  }
}
