@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 24px;
  @include svgSize(32px);
  @extend %flex-column-center;

  &_modal {
    max-width: 450px;
  }

  &_text {
    color: $gray-500;
    text-align: center;
    @extend %secondary-1;
  }

  &_title {
    color: $gray-900;
    text-align: center;
    @extend %secondary-5;
  }

  &_link {
    gap: 10px;
    color: $primary-500;
    @extend %flex-align;
    @extend %secondary-3;
    @include svgFill($primary-500, 20px);
  }

  &_subtitle {
    margin-top: 4px;
    color: $gray-900;
    text-align: center;
    @extend %secondary-3;
  }

  &_container {
    gap: 8px;
    @extend %flex-align;
    @include svgSize(20px);
  }

  &_success > path {
    fill: $success-500;
  }

  &_warning > path {
    fill: $primary-500;
  }
}
