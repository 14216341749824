@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 24px;
  width: 100%;
  max-width: 466px;
  padding: 24px 16px;
  background-color: $white;
  box-shadow: 0px 0px 10px 0px #10182833;
  @extend %flex-column;

  &_head {
    width: 100%;
    @extend %flex-end;
    @include svgFill($gray-700, 20px);

    &_text {
      gap: 12px;
      color: $gray-900;
      margin-top: -32px;
      @extend %flex-align;
      @extend %secondary-1;
      @include svgFill($success-600, 24px);
    }
  }

  &_container {
    gap: 16px;
    @extend %flex-align;

    @media (max-width: $mobile-w) {
      flex-direction: column;
    }

    &_left {
      height: 140px;
      min-width: 116px;
      max-width: 116px;
      border-radius: 4.71px;

      @media (max-width: $tablet) {
        height: 120px;
        min-width: 96px;
        max-width: 96px;
      }
    }

    &_right {
      gap: 8px;
      @extend %flex-column;
    }
  }

  &_title {
    color: $gray-900;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 275px;
    @extend %subheading-2;

    @media (max-width: $tablet) {
      max-width: 230px;
      font-size: 16px;
      font-weight: 700;
      line-height: 100%;
    }
  }

  &_description {
    color: $gray-500;
    @extend %secondary-1;

    @media (max-width: $tablet) {
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
    }
  }

  &_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4.71px;
    object-position: center;
  }

  &_button_container {
    gap: 12px;
    @extend %flex-column;
  }
}
