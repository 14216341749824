@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 8px;
  width: 100%;
  max-width: 305px;
  cursor: pointer;
  @extend %flex-column-center;

  &_container {
    overflow: hidden;
    height: 305px;
    max-height: 305px;
    width: 100%;

    @media (max-width: $tablet-l) {
      height: 165.5px;
      max-height: 165.5px;
    }
  }
}
