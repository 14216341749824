@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 20px;
  width: 100%;
  @extend %flex-column;

  &_container {
    gap: 20px;
    width: 100%;
    display: flex;

    @media (max-width: $tablet) {
      gap: 16px;
    }
  }

  &_left {
    gap: 24px;
    cursor: pointer;
    @extend %flex-column;

    &_image {
      min-width: 197px;
      max-width: 197px;
      height: 236px;
      max-height: 236px;
      object-fit: cover;
      border-radius: 8px;

      @media (max-width: $tablet-l) {
        min-width: 157px;
        max-width: 157px;
        height: 186px;
        max-height: 186px;
      }

      @media (max-width: $tablet) {
        min-width: 56px;
        max-width: 56px;
        height: 72px;
        max-height: 72px;
      }
    }
  }

  &_right {
    width: 100%;
    padding: 14px 0;
    @extend %flex-column;

    @media (max-width: $tablet) {
      padding: 0;
    }

    &_container {
      height: 100%;
      @extend %flex-column-between;
    }

    &_bottom {
      gap: 12px;
      align-items: flex-end;
      @extend %flex-justify-between;
      @include svgFill($error-500, 24px);
    }
  }

  &_title {
    color: $gray-900;
    white-space: nowrap;
    @extend %subheading-6;

    @media (max-width: $tablet) {
      font-weight: 500;
      font-size: 14px;
      line-height: 19.6px;
    }

    &_name {
      min-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100vw - 1000px);

      @media (max-width: $tablet-w) {
        min-width: 100px;
        width: calc(100vw - 384px);
      }

      @media (max-width: $tablet) {
        max-width: calc(100vw - 200px);
      }
    }

    &_container {
      gap: 12px;
      cursor: pointer;
      @extend %flex-justify-between-center;

      @media (max-width: $tablet-w) {
        align-items: flex-start;
      }
    }
  }

  &_description {
    color: $gray-500;
    @extend %secondary-1;

    @media (max-width: $tablet) {
      font-weight: 400;
      font-size: 12px;
      line-height: 16.8px;
    }

    &_container {
      gap: 8px;
      padding: 12px 0 24px;
      @extend %flex-column;

      @media (max-width: $tablet) {
        gap: 4px;
        padding: 8px 0 20px;
      }
    }
  }

  &_quantity {
    height: 56px;
    cursor: pointer;
    text-align: center;
    transition: all 0.6s;
    background-color: $white;
    border: 1px solid $gray-300;
    @extend %micro-3;
    @extend %flex-center;
    @include svgFill($gray-900, 24px);

    @media (max-width: $tablet) {
      height: 44px;
      @include svgFill($gray-900, 20px);
    }

    &:hover {
      background-color: $gray-300;
    }

    &_container {
      display: flex;
    }

    &_left {
      width: 44px;
      border-radius: 8px 0 0 8px;

      @media (max-width: $tablet) {
        width: 40px;
      }
    }

    &_center {
      width: 76px;
      border-left: 0;
      border-right: 0;
      user-select: none;

      @media (max-width: $tablet) {
        width: 64px;
      }

      &:hover {
        background-color: $white;
      }
    }

    &_right {
      width: 44px;
      border-radius: 0 8px 8px 0;

      @media (max-width: $tablet) {
        width: 40px;
      }
    }
  }
}
