@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  width: 100%;
  gap: 8px;
  padding-bottom: 50px;
  @extend %flex-column;

  &_title {
    color: $gray-900;
    text-align: center;
    padding-bottom: 12px;
    @extend %micro-5;
  }

  &__slider {
    width: 100%;
    height: 4px;
    background: #d1d5db;
    border-radius: 2px;
    position: relative;
  }

  &_track {
    height: 1.54px;
    background: $gray-900;
  }

  &_thumb {
    width: 24px;
    height: 24px;
    cursor: pointer;
    background: $white;
    position: relative;
    top: -12px;
    border-radius: 50%;
    border: 7px solid $gray-900;

    &_text {
      margin-top: 24px;
      color: $gray-900;
      text-align: center;
      @extend %micro-4;
    }
  }
}
