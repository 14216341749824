@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 12px;
  padding: 4px;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.6s;
  border: 1px solid $gray-300;
  position: relative;
  @extend %flex-align;
  @include minMaxSize(322px);

  &:hover {
    border-color: $gray-700;
  }

  &_text {
    color: $gray-500;
    @extend %micro-2;
  }

  &_left {
    width: 72px;
    height: 100%;
    border-radius: 6px;
    @include svgSize(24px, 24px);

    &_image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
    }
  }

  &_right {
    gap: 2px;
    padding: 7px 12px 7px 0;
    @extend %flex-column;
  }

  &_close {
    right: 8px;
    top: 8px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    border-radius: 50%;
    background-color: $white;
    @extend %flex-center;
    @include svgFill($primary-500, 20px);
  }

  &_title {
    color: $gray-900;
    max-width: 195px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @extend %microSb-5;
  }

  &_price {
    color: $gray-900;
    text-transform: lowercase;
    @extend %microSb-5;

    &_special {
      color: $gray-500;
      text-decoration: line-through;
    }
  }
}
