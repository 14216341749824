@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 24px;
  height: 408px;
  @extend %flex-column;

  &_crop {
    height: 100%;
    display: flex;
    height: 300px;
    position: relative;
    justify-content: center;

    &_container {
      width: 100%;
      border-radius: 8px;
      position: relative !important;
    }
  }

  &_button_container {
    gap: 12px;
    @extend %flex-align;
  }
}
