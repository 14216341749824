@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 12px;
  width: 100%;
  cursor: pointer;
  padding: 16px 24px;
  border-radius: 16px;
  transition: all 0.6s;
  background-color: $white;
  border: 1px solid $gray-200;
  @extend %flex-column-center;

  @media (max-width: $tablet) {
    padding: 16px;
  }

  &_active {
    border-color: $primary-500;

    .wrapper_icon {
      @include svgFill($primary-500);
    }
  }

  &_icon {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 6px solid $gray-50;
    background-color: $gray-100;
    @extend %flex-center;
    @include svgFill($gray-500, 24px);

    @media (max-width: $tablet) {
      width: 40px;
      height: 40px;
      @include svgFill($gray-500, 20px);
    }
  }

  &_container {
    gap: 4px;
    max-width: 256px;
    @extend %flex-column-center;
  }

  &_text {
    color: $gray-500;
    text-align: center;
    @extend %micro-5;

    @media (max-width: $tablet) {
      font-weight: 400;
      font-size: 14px;
      line-height: 19.6px;
      letter-spacing: 0%;
    }

    &_special {
      cursor: pointer;
      color: $primary-500;
      @extend %microSb-5;

      @media (max-width: $tablet) {
        font-weight: 500;
        font-size: 14px;
        line-height: 19.6px;
        letter-spacing: 0%;
      }
    }
  }

  &_format {
    text-align: center;
    color: $gray-500;
    @extend %micro-2;
  }
}
