@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  width: 100%;
  min-width: 305px;
  cursor: pointer;

  @media (max-width: $tablet) {
    min-width: 165.5px;
  }

  &:hover {
    .wrapper_image {
      scale: 1.15;
    }
  }

  &_header {
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 8px;

    &_container {
      top: 8px;
      left: 0;
      padding: 0 8px;
      z-index: 2;
      position: absolute;
      width: 100%;
      @extend %flex-justify-between;
    }
  }

  &_image {
    width: 100%;
    height: 305px;
    object-fit: cover;
    transition: all 0.6s;
    background-color: $gray-100;
    @extend %flex-center;
    @include svgFill($gray-300, 120px);

    @media (max-width: $tablet) {
      height: 208px;
      @include svgFill($gray-300, 56px);
    }
  }

  &_saved {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: $white;
    @extend %flex-center;
  }

  &_bottom {
    gap: 8px;
    padding-top: 12px;
    @extend %flex-column;

    @media (max-width: $tablet) {
      gap: 4px;
      padding-top: 8px;
    }
  }

  &_title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 305px;
    color: $gray-900;
    @extend %secondary-3;

    @media (max-width: $tablet) {
      max-width: 165.5px;
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
    }
  }

  &_price {
    color: $gray-900;
    @extend %secondary-3;

    @media (max-width: $tablet) {
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
    }

    &_special {
      color: $gray-400;
      text-decoration: line-through;
    }
  }

  &_badge {
    padding: 2px 8px;
    width: max-content;
    border-radius: 4px;
    @extend %micro-2;

    &_container {
      gap: 4px;
      @extend %flex-column;
    }

    &_primary {
      color: $white;
      background-color: $primary-500;
    }

    &_secondary {
      color: $gray-900;
      background-color: $white;
    }
  }
}
