@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  width: 100%;
  @extend %flex-column-between;

  &_badge {
    gap: 6px;
    height: 21px;
    border-radius: 16px;
    padding: 2px 8px;
    background-color: $warning-50;
    white-space: nowrap;
    color: $error-700;
    @extend %flex-align;
    @extend %micro-4;
  }

  &_item_container {
    gap: 12px;
    display: flex;
    overflow-x: auto;
    border-radius: 8px;
    @include scrollbarHorizontal(0);
  }

  &_dot {
    border-radius: 50%;
    background-color: $warning-600;
    @include minMaxSize(6px, 6px);
  }

  &_line {
    width: 100%;
    height: 1px;
    background-color: $gray-300;
  }

  &_container {
    gap: 16px;
    width: 100%;
    padding-top: 24px;
    overflow-y: auto;
    height: calc(100dvh - 148px);
    @extend %flex-column;
    @include scrollbar(10px);

    @media (max-width: $tablet) {
      padding-top: 20px;
    }
  }

  &_row {
    gap: 12px;
    width: 100%;
    @extend %flex-justify-between-center;

    &_wrap {
      flex-wrap: wrap;
    }
  }

  &_column {
    gap: 16px;
    padding: 0 24px;
    @extend %flex-column;

    @media (max-width: $tablet) {
      padding: 0 16px;
    }
  }

  &_column_12 {
    gap: 12px;
    padding: 0 24px;
    @extend %flex-column;

    @media (max-width: $tablet) {
      padding: 0 12px;
    }
  }

  &_column_6 {
    gap: 6px;
    @extend %flex-column;
  }

  &_text {
    color: $gray-500;
    @extend %micro-2;

    &_bold {
      gap: 10px;
      color: $gray-700;
      @extend %micro-5;
      @extend %flex-align;
      @include svgFill($gray-900, 16px);
    }

    &_copied {
      @include svgFill($success-500, 18px);
    }

    &_bottom {
      color: $gray-900;
      @extend %microSb-5;
    }
  }

  &_bottom {
    gap: 12px;
    width: 100%;
    padding: 16px 24px;
    border-top: 1px solid $gray-300;
    @extend %flex-align;
  }

  &_button {
    max-height: 36px;
  }

  &_products {
    max-width: unset !important;

    &_image {
      margin: 0 auto;
    }

    &_head {
      padding-top: 24px !important;
    }

    &_container {
      gap: 16px;
      width: 100%;
      padding: 0 24px;
      overflow-y: auto;
      height: calc(100dvh - 324px);
      @extend %flex-column;
      @include scrollbar(10px);
    }

    p {
      max-width: 300px;
      white-space: wrap;
    }
  }
}

.edit {
  &_head {
    gap: 12px;
    padding: 0 24px 16px;
    border-bottom: 1px solid $gray-300;
    @extend %flex-column;
  }

  &_container {
    gap: 16px;
    padding: 16px 24px 24px;
    @extend %flex-column;
  }

  &_text {
    gap: 4px;
    cursor: pointer;
    color: $primary-500;
    text-decoration: underline;
    @extend %flex-align;
    @extend %secondary-3;
    @include svgFill($primary-500, 20px);
  }
}
