$white: #fff;
$white-100: #f5f5f5;

$red: #ab0000;
$red-2: #ff1520;
$yellow: #ffc107;

$black: #000;
$black-100: #1a1a1a;
$black-200: #21201e;
$black-300: #4c5563;
$black-400: #959595;
$black-500: #323232;
$black-600: #091524;

$info: #5c90dd;
$warning: #f0b774;
$success: #47cb92;

//colors with opacity

$opacity-1: rgba(81, 80, 80, 0.2);
$opacity-2: rgba(255, 255, 255, 0.1);
$opacity-3: rgba(149, 149, 149, 0.2);
$opacity-4: rgba(26, 26, 26, 0.1);

//Color system

//Gray
$gray-0: #fcfcfd;
$gray-5: #fafafb;
$gray-10: #616264;
$gray-15: #e5e7e7;
$gray-20: #d1d1d1;
$gray-50: #f9fafb;
$gray-100: #f2f4f7;
$gray-200: #eaecf0;
$gray-300: #d0d5dd;
$gray-400: #98a2b3;
$gray-500: #667085;
$gray-600: #475467;
$gray-700: #344054;
$gray-900: #101828;
$gray-1000: #19171b;

//Primary

$primary-15: #f7e5e5;
$primary-25: #f7e6e6;
$primary-50: #eabfbf;
$primary-100: #eabfbf;
$primary-200: #dd9999;
$primary-500: #ab0000;
$primary-600: #910000;

//Error
$error-25: #fffbfa;
$error-50: #fef3f2;
$error-100: #fee4e2;
$error-300: #fda29b;
$error-500: #f04438;
$error-600: #d92d20;
$error-700: #b42318;

//Warning

$warning-50: #fffaeb;
$warning-300: #fec84b;
$warning-600: #dc6803;

//Success
$success-400: #32d583;
$success-500: #12b76a;
$success-600: #039855;
