@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 64px;
  padding: 96px 80px;
  overflow: hidden;
  @extend %flex-justify-between-center;

  @media (max-width: $wide) {
    gap: 32px;
  }

  @media (max-width: $tablet-w) {
    padding: 48px 16px;
    flex-direction: column;
  }

  @media (max-width: $tablet) {
    gap: 24px;
    padding: 48px 0;
  }

  &_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 25% 30%;
    border-radius: 16px;

    @media (max-width: $tablet) {
      border-radius: 0;
    }

    &_container {
      width: 100%;
      height: 524px;

      @media (max-width: $tablet) {
        height: 375px;
      }
    }
  }

  &_container {
    gap: 16px;
    width: 100%;
    @extend %flex-column;

    @media (max-width: $wide) {
      gap: 8px;
    }

    @media (max-width: $tablet) {
      gap: 16px;
      padding: 0 16px;
    }
  }

  &_title {
    color: $gray-1000;
    @extend %heading-3;

    @media (max-width: $wide) {
      font-size: 24px;
      line-height: 36px;
    }

    @media (max-width: $tablet) {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
  }

  &_description {
    gap: 8px;
    @extend %flex-column;
  }

  &_subtitle {
    color: $gray-10;
    @extend %micro-5;
  }

  &_text {
    color: $gray-1000;
    font-style: italic;
    @extend %microB-5;
  }
}
