@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

$mainBannerImage: '../../../../assets/images/home/mainBanner.png';

.wrapper {
  width: 100%;
  min-height: 448px;
  height: calc(100vh - 116px);
  background:
    linear-gradient(360deg, rgba(16, 24, 40, 0) -74.02%, rgba(16, 24, 40, 0.3) 37.95%),
    url($mainBannerImage) center / cover;
  @extend %flex-center;

  &_container {
    width: 100%;
    gap: 16px;
    max-width: 847px;
    color: $white;
    text-align: center;
    padding: 0 16px;
    @extend %flex-column-center;

    @media (max-width: $tablet) {
      gap: 12px;
    }
  }

  &_title {
    text-transform: uppercase;
    @extend %heading-2;

    @media (max-width: $tablet) {
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
    }
  }

  &_subtitle {
    @extend %subheading-1;

    @media (max-width: $tablet) {
      font-size: 16px;
      font-weight: 400;
      line-height: 22.4px;
    }
  }

  &_button {
    margin-top: 16px;
    width: max-content;

    @media (max-width: $tablet) {
      margin-top: 20px;
    }
  }
}
