@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 8px;
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.6s;
  @extend %flex-center;
  @extend %secondary-3;
  padding: 12px 24px;
  white-space: nowrap;
  border: 1px solid transparent;
  box-shadow: 0px 1px 2px 0px #1018280d;
  @include svgSize(20px, 20px);

  &_max_content {
    max-width: max-content;
  }
}

.primary {
  color: $white;
  border-color: $primary-500;
  background-color: $primary-500;
  @include svgFill($white);

  &:disabled,
  &:disabled:hover {
    border-color: $primary-100;
    background-color: $primary-100;
  }

  &:hover {
    background-color: $primary-600;
    border-color: $primary-600;
  }

  &:focus {
    box-shadow:
      0px 0px 0px 4px $primary-25,
      0px 1px 2px 0px #1018280d;
  }
}

.secondary {
  color: $gray-700;
  background-color: $white;
  border-color: $gray-300;
  @include svgSize(20px, 20px);
  @include svgFill($gray-700);

  &:disabled,
  &:disabled:hover {
    color: $gray-300;
    border-color: $gray-200;
    @include svgFill($gray-300);
  }

  &:hover {
    color: $gray-900;
    border-color: $gray-300;
  }

  &:focus {
    box-shadow:
      0px 0px 0px 4px $gray-100,
      0px 1px 2px 0px #1018280d;
  }
}

.large {
  padding-top: 16px;
  padding-bottom: 16px;
  max-height: 48px;
}

.medium {
  max-height: 44px;
}

.small {
  max-height: 40px;
}
