@use 'styles/vars' as *;
@use 'styles/colors' as *;
@use 'styles/mixins' as *;
@use 'styles/typography' as *;

.wrapper {
  gap: 2px;
  cursor: pointer;
  color: $gray-900;
  border-radius: 4px;
  padding: 10px 20px;
  transition: all 0.3s;
  background-color: $gray-100;
  @extend %micro-5;
  @extend %flex-column-center;

  &:hover,
  &__active {
    color: $white;
    background-color: $primary-500;
  }

  &__disabled,
  &__disabled:hover {
    opacity: 0.6;
    color: $gray-300;
    background-color: $gray-100;
  }
}
