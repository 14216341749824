@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  padding: 0;
  max-width: 424px;
  @extend %flex-column;

  &_title {
    color: $gray-900;
    @extend %secondary-3;

    &_container {
      gap: 4px;
      padding: 24px 24px 16px;
      border-bottom: 1px solid $gray-200;
      @extend %flex-column;
    }
  }

  &_subtitle {
    color: $gray-500;
    @extend %micro-5;
  }

  &_container {
    gap: 16px;
    padding: 24px;
    overflow-y: auto;
    @include scrollbar(16px);
    @extend %flex-column;
  }

  &_checkboxes {
    gap: 16px;
    @extend %flex-column;

    &_title {
      color: $gray-900;
      @extend %secondary-1;
    }
  }

  &_bottom {
    padding: 16px 24px;
    border: 1px solid $gray-200;
  }
}
