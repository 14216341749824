@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

$bannerImage: '../../../../assets/images/home/reviewBannerImage.png';

.wrapper {
  gap: 64px;
  display: flex;
  overflow: hidden;
  border-radius: 16px;
  background-color: $gray-5;
  height: 524px;

  @media (max-width: $tablet-w) {
    gap: 0;
    height: auto;
    flex-direction: column;
    margin-top: 32px;
  }

  &_left {
    gap: 16px;
    width: 100%;
    padding: 64px 0 64px 64px;
    @extend %flex-column-justify-center;

    @media (max-width: $tablet-w) {
      padding: 48px 16px;
      align-items: center;
    }

    &_title {
      color: $gray-900;
      @extend %heading-3;

      @media (max-width: $tablet-w) {
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
        text-align: center;
      }
    }

    &_subtitle {
      color: $gray-500;
      @extend %subheading-3;

      @media (max-width: $tablet-w) {
        font-size: 16px;
        font-weight: 400;
        line-height: 22.4px;
        text-align: center;
      }
    }
  }

  &_right {
    width: 100%;
    display: flex;
    padding: 32px;
    align-items: flex-end;
    background-size: cover;
    background-position: center;
    border-radius: 0 16px 16px 0;
    background-image: url($bannerImage);

    @media (max-width: $tablet-w) {
      height: 380px;
      padding: 12px;
      border-radius: 0 0 16px 16px;
    }

    &_container {
      gap: 20px;
      width: 100%;
      padding: 24px;
      border-radius: 12px;
      backdrop-filter: blur(6px);
      background-color: #10182833;
      @extend %flex-column;

      @media (max-width: $tablet-w) {
        padding: 16px;
      }
    }

    &_title {
      color: $white;
      @extend %subheading-4;

      @media (max-width: $tablet-w) {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
      }
    }

    &_name {
      color: $white;
      @extend %secondary-4;

      @media (max-width: $tablet-w) {
        font-size: 14px;
        font-weight: 400;
        line-height: 19.6px;
      }
    }

    &_stars {
      gap: 6px;
      @extend %flex-column;
      @include svgFill(#ffd339, 16px);

      &_container {
        gap: 4px;
        @extend %flex-align;
      }
    }
  }

  &_button {
    margin-top: 32px;
    max-width: max-content;

    @media (max-width: $tablet-w) {
      margin-top: 16px;
    }
  }
}
