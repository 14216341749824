@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  width: 100%;
  gap: 64px;
  padding: 64px 80px 48px;
  background-color: $gray-900;
  @extend %flex-column;

  @media (max-width: $tablet-w) {
    gap: 32px;
    padding: 64px 24px 48px;
  }

  @media (max-width: $tablet) {
    gap: 48px;
    padding: 48px 16px;
  }

  &_logo {
    @media (max-width: $tablet) {
      width: 148px;
      height: 32px;
    }
  }

  &_link {
    transition: all 0.6s;

    &:hover {
      color: $white;
      @include svgFill($white);
    }
  }

  &_row_container {
    gap: 32px;
    display: flex;

    @media (max-width: $tablet) {
      gap: 16px;
      flex-direction: column;
    }
  }

  &_links_container {
    gap: 32px;
    display: flex;

    @media (max-width: $tablet) {
      justify-content: space-between;
    }
  }

  &_between_container {
    gap: 32px;
    @extend %flex-justify-between;

    @media (max-width: $tablet) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: $mobile-w) {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  &_text {
    color: $gray-200;
    @extend %secondary-1;
  }

  &_description {
    white-space: pre-line;

    @media (max-width: $mobile-w) {
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
    }
  }

  &_column_container {
    gap: 32px;
    width: 100%;
    @extend %flex-column;
  }

  &_right {
    gap: 24px;
    align-items: flex-end;
    text-align: right;
    white-space: nowrap;
    @extend %flex-column;

    @media (max-width: $tablet) {
      gap: 16px;
      align-items: flex-start;
      text-align: center;
    }
  }

  &_badges {
    gap: 24px;
    align-items: flex-end;
    @extend %flex-column;

    @media (max-width: $tablet) {
      gap: 16px;
      flex-direction: row;
    }
  }

  &_bottom {
    gap: 32px;
    padding-top: 31px;
    border-top: 1px solid $gray-600;
    @extend %flex-justify-between-center;

    @media (max-width: $mobile-w) {
      gap: 24px;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column-reverse;
    }

    &_copyright {
      color: $gray-400;
    }

    &_social {
      gap: 24px;
      @extend %flex-align;
    }
  }
}
