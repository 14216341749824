@use 'styles/vars.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 8px;
  cursor: pointer;
  @extend %flex-align;

  input {
    display: none;
  }

  &_circle {
    width: 24px;
    height: 24px;

    & > path {
      fill: $gray-300;
    }
  }

  &_dot {
    width: 24px;
    height: 24px;

    & > path {
      fill: $primary-500;
    }
  }

  &_label {
    color: $gray-900;
    @extend %secondary-3;

    @media (max-width: $tablet) {
      font-weight: 500;
      font-size: 14px;
      line-height: 19.6px;
    }
  }
}
