@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
  transition: 0.6s all;
  background-color: $white;
  border: 1px solid $gray-300;
  @extend %flex-column;

  &:hover {
    box-shadow:
      0px 1px 2px 0px #1018280f,
      0px 1px 3px 0px #1018281a;
  }

  &_badge {
    gap: 6px;
    height: 21px;
    border-radius: 16px;
    padding: 2px 8px;
    background-color: $warning-50;
    color: $error-700;
    white-space: nowrap;
    @extend %flex-align;
    @extend %micro-4;
  }

  &_item_container {
    gap: 12px;
    display: flex;
    overflow-x: auto;
    border-radius: 8px;
    @include scrollbarHorizontal(0);
  }

  &_dot {
    border-radius: 50%;
    background-color: $warning-600;
    @include minMaxSize(6px, 6px);
  }

  &_container {
    gap: 16px;
    width: 100%;
    padding: 24px;
    @extend %flex-column;

    @media (max-width: $tablet) {
      padding: 20px 16px;
    }
  }

  &_row {
    gap: 8px;
    width: 100%;
    @extend %flex-justify-between-center;

    &_wrap {
      flex-wrap: wrap;
    }
  }

  &_column {
    gap: 8px;
    @extend %flex-column;

    @media (max-width: $tablet) {
      gap: 6px;
    }
  }

  &_text {
    color: $gray-500;
    @extend %micro-2;

    &_bold {
      gap: 10px;
      color: $gray-700;
      @extend %micro-5;
      @extend %flex-align;
      @include svgFill($gray-900, 18px);
    }

    &_copied {
      @include svgFill($success-500, 18px);
    }

    &_nowrap {
      white-space: nowrap;
    }

    &_bottom {
      color: $gray-900;
      @extend %microSb-5;
    }
  }

  &_bottom {
    gap: 12px;
    width: 100%;
    height: 100%;
    padding: 16px 24px;
    border-top: 1px solid $gray-300;
    @extend %flex-column-center;

    @media (max-width: $tablet) {
      padding: 16px;
    }
  }

  &_button {
    max-height: 36px;
  }
}
