@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  position: relative;
  width: 100%;
  gap: 4px;
  @extend %flex-column;

  &__label {
    color: $gray-700;
    @extend %microSb-5;
  }

  &__flag {
    width: 24px;
    height: 24px;
    user-select: none;
    border-radius: 50%;
    border: 1px solid $gray-200;
  }

  &__search {
    padding: 12px;
  }

  &__input {
    padding-left: 62px !important;

    &__container {
      gap: 8px;
      width: 100%;
      @extend %flex-align;
      position: relative;
    }

    &__lang {
      position: absolute;
      top: 50%;
      z-index: 1;
      left: 14px;
      transform: translateY(-50%);
      cursor: pointer;
      @extend %flex-align;
      @include svgFill($gray-500, 24px);
    }
  }

  &__loader div {
    border-width: 2px;

    &:nth-child(1) {
      border-color: $red;
    }
  }

  &__loader__container {
    width: 100%;
    @extend %flex-center;
  }

  &__empty {
    padding: 12px;
    margin: 12px;
    width: 100%;
    text-align: center;
    border-radius: 8px;
    background-color: #f1bcbc;
    color: $red;
  }

  &__menu {
    gap: 12px;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    box-shadow: 0px 4px 10px -2px #1018281a;
    border-radius: 10px;
    padding: 12px;
    @extend %flex-column;
    position: absolute;
    top: 80px;
    left: 0;
    min-width: 280px;
    width: 100%;
    z-index: 2;

    &__container {
      max-height: 235px;
      overflow-y: auto;
      @include scrollbar(12px);
    }

    &__item {
      padding: 10px 14px;
      border-radius: 10px;
      gap: 8px;
      background-color: $white;
      transition: 0.6s all;
      cursor: pointer;
      @extend %flex-justify-between-center;
      color: $gray-900;
      @extend %secondary-1;

      &:hover,
      &__selected {
        background-color: $gray-50;
      }

      &__container {
        gap: 8px;
        @extend %flex-align;
      }

      &__icon {
        width: 20px;
        height: 20px;

        & > path {
          fill: $primary-500;
        }
      }

      &__prefix {
        padding: 0 8px;
        height: 24px;
        color: $gray-500;
        border-radius: 180px;
        border: 1px solid $gray-300;
        background-color: $white;
        @extend %secondary-1;
        @extend %flex-center;
      }
    }
  }
}
