@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 12px;
  cursor: pointer;
  width: max-content;
  transition: all 0.6s;
  justify-content: space-between;
  @extend %flex-column-center;

  &_container {
    overflow: hidden;
    border-radius: 50%;
    width: 196px;
    height: 196px;

    @media (max-width: $tablet) {
      width: 132px;
      height: 132px;
    }
  }

  &_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    transition: all 0.6s;
  }
}
