@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 48px;
  padding: 96px 48px;
  background-color: $gray-5;
  @extend %flex-column;

  @media (max-width: $tablet) {
    gap: 24px;
    padding: 48px 16px;
  }

  &_header {
    gap: 16px;
    width: 100%;
    max-width: 720px;
    margin: 0 auto;
    @extend %flex-column-center;

    @media (max-width: $tablet) {
      gap: 12px;
    }

    &_title {
      color: $gray-900;
      text-align: center;
      @extend %heading-3;

      @media (max-width: $tablet) {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
      }
    }

    &_subtitle {
      color: $gray-500;
      text-align: center;
      @extend %subheading-3;

      @media (max-width: $tablet) {
        font-size: 16px;
        font-weight: 400;
        line-height: 22.4px;
      }
    }
  }

  &_container {
    gap: 20px;
    display: flex;

    @media (max-width: $desktop) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: $tablet-m) {
      gap: 12px;
      display: flex;
      flex-direction: column;
    }
  }
}
