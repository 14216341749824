@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 12px;
  cursor: pointer;
  width: max-content;
  transition: all 0.6s;
  justify-content: space-between;
  @extend %flex-column-center;

  &:hover {
    .wrapper_image {
      scale: 1.15;
    }

    .wrapper_empty {
      scale: 1.15;
    }
  }

  &_container {
    overflow: hidden;
    border-radius: 50%;
    width: 196px;
    height: 196px;

    @media (max-width: $tablet) {
      width: 132px;
      height: 132px;
    }
  }

  &_image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
    transition: all 0.6s;
  }

  &_empty {
    width: 100%;
    height: 100%;
    transition: all 0.6s;
    background-color: $gray-100;
    @extend %flex-center;
    @include svgFill($gray-300, 72px);

    @media (max-width: $tablet) {
      @include svgFill($gray-300, 48px);
    }
  }

  &_text {
    color: $gray-1000;
    text-align: center;
    @extend %secondary-3;

    @media (max-width: $tablet) {
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
    }
  }
}
