@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 12px;
  @include svgSize(32px);
  @extend %flex-column-center;

  &_modal {
    max-width: 450px;
  }

  &_text {
    color: $gray-900;
    text-align: center;
    @extend %secondary-1;
  }

  &_title {
    color: $gray-900;
    text-align: center;
    @extend %subheading-1;
  }

  &_container {
    gap: 8px;
    @extend %flex-align;
    @include svgSize(20px);
  }

  &_success > path {
    fill: $success-500;
  }

  &_warning > path {
    fill: $primary-500;
  }
}
