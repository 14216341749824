@mixin svgFill($color, $size: false) {
  svg {
    @if $size {
      width: $size;
      height: $size;
    }

    path {
      transition: all 0.5s;
      fill: $color;
      @content;
    }
  }
}

@mixin svgSize($size, $minMaxSize: false) {
  svg {
    width: $size;
    height: $size;

    @if $minMaxSize {
      min-width: $minMaxSize;
      min-height: $minMaxSize;
      max-width: $minMaxSize;
      max-height: $minMaxSize;
    }
  }
}

@mixin scrollbar($width) {
  &::-webkit-scrollbar {
    width: $width;

    @content;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #eaecf0;
    border-radius: 6px;
    border: 2px solid #ffff;
  }
}

@mixin scrollbarHorizontal($height) {
  &::-webkit-scrollbar {
    height: $height;

    @content;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #eaecf0;
    border-radius: 6px;
    border: 2px solid #ffff;
  }
}

@mixin inputStyles() {
  color: #101828;
  width: 100%;
  padding: 9px 14px;
  max-height: 44px;
  border-radius: 8px;
  transition: all 0.6s;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px 0px #1018280d;
  @extend %secondary-1;

  &:hover {
    border-color: #344054;
  }

  &:focus {
    border-color: #eabfbf !important;
    box-shadow:
      0px 0px 0px 4px #f7e5e5,
      0px 1px 2px 0px #1018280d;
  }

  &::placeholder {
    color: #667085;
  }
}

@mixin minMaxSize($width: false, $height: false) {
  @if ($height and $width == false) {
    min-height: $height;
    max-height: $height;
  }

  @if (($height == false and $width)) {
    min-width: $width;
    max-width: $width;
  }

  @if ($width and $height) {
    min-height: $height;
    max-height: $height;
    min-width: $width;
    max-width: $width;
  }
}
