@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  border-radius: 50%;
  border: 10px solid transparent;
  @include minMaxSize(56px, 56px);
  @include svgSize(28px, 28px);
  @extend %flex-center;
}

.danger {
  border-color: $error-50;
  background-color: $error-100;
  @include svgFill($error-600);
}
