@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  position: relative;

  &__container {
    width: 100%;
    z-index: 4;
    left: 50%;
    right: 50%;
    top: 115px;
    opacity: 0;
    padding: 0;
    max-height: calc(100vh - 115px);
    height: calc(100vh - 115px);
    position: fixed;
    overflow: hidden;
    transform: translate(-50%);
    transition: all 0.3s;
    background: #10182833;
    backdrop-filter: blur(1px);
    @extend %flex-justify-center;
  }

  &__dropdown {
    background-color: $white;
    width: 100vw;
    display: flex;
    max-height: 424px;
    transition: all 0.3s;
    box-shadow:
      0px 4px 6px -2px #10182808,
      0px 12px 16px -4px #10182814;

    &__dropped {
      opacity: 1;
      max-height: unset;
    }

    &__right {
      width: 100%;
      padding: 24px 32px;

      &_container {
        @extend %flex-column;
      }

      &_scrollable {
        overflow-y: auto;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        height: calc(100% - 50px);
        gap: 24px;
        @include scrollbar(12px);
      }

      &_bottom {
        width: 100%;
        background-color: $white;
      }

      &__item {
        gap: 60px;
        display: flex;
        flex-wrap: wrap;

        &:first-child {
          margin-top: 8px;
        }
      }

      &__text {
        color: $gray-500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 236px;
        padding: 10px 0;
        cursor: pointer;
        transition: all 0.6s;
        text-transform: capitalize;
        @extend %secondary-1;

        &:hover {
          color: $gray-900;
        }

        &_title {
          color: $gray-900;
          cursor: pointer;
          text-transform: capitalize;
          @extend %microSb-5;
        }
      }
    }

    &__left {
      width: 100%;
      max-width: 385px;
      overflow-y: auto;
      border-right: 1px solid $gray-200;
      @include scrollbar(12px);
      @extend %flex-column;

      &__title {
        color: $red;
        padding: 24px 24px 12px;
        @extend %microSb-5;
      }

      &__item {
        width: 100%;
        cursor: pointer;
        transition: 0.6s;
        padding: 10px 24px;
        @extend %flex-justify-between;
        @include svgFill($gray-500);

        &_container {
          gap: 12px;
          @extend %flex-align;

          & > img {
            width: 24px;
            height: 24px;
          }
        }

        &__active,
        &:hover {
          background-color: $gray-50;
          @include svgFill($gray-900);

          .wrapper__dropdown__left__item__text {
            color: $gray-900;
          }
        }

        &__text {
          transition: 0.6s;
          color: $gray-500;
          text-transform: capitalize;
          @extend %secondary-3;
        }
      }
    }
  }
}

.sidebar {
  overflow: hidden;

  &_item {
    gap: 8px;
    cursor: pointer;
    padding: 10px 16px;
    transition: all 0.6s;
    border-bottom: 1px solid $gray-200;
    @include svgFill($gray-900);
    @extend %flex-justify-between-center;

    &_container {
      gap: 16px;
      @extend %flex-align;

      & > img {
        overflow: hidden;
      }
    }

    &_text {
      color: $gray-900;
      @extend %secondary-1;

      &_all {
        cursor: pointer;
        color: $primary-500;
      }
    }
  }
}

.enter {
  opacity: 0;
  transform: translateX(100%);
}
.enterActive {
  opacity: 1;
  transform: translateX(0);
  transition: all 300ms;
}
.exit {
  opacity: 1;
  transform: translateX(0);
}
.exitActive {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 300ms;
}

.enter_reverse {
  transform: translateX(-100%);
  opacity: 0;
}

.enterActive_reverse {
  transform: translateX(0);
  opacity: 1;
  transition:
    transform 300ms ease-in-out,
    opacity 300ms ease-in-out;
}

.exit_reverse {
  transform: translateX(0);
  opacity: 1;
}

.exitActive_reverse {
  transform: translateX(100%);
  opacity: 0;
  transition:
    transform 300ms ease-in-out,
    opacity 300ms ease-in-out;
}
