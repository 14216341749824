@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  width: 100%;
  height: 305px;
  transition: all 0.6s;
  background-color: $gray-100;
  @extend %flex-center;
  @include svgFill($gray-300, 120px);

  @media (max-width: $tablet) {
    height: 208px;
    @include svgFill($gray-300, 56px);
  }
}
