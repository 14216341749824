@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  width: 100%;
  @extend %flex-column;

  &_header {
    gap: 12px;
    padding: 20px 24px;
    cursor: pointer;
    color: $gray-1000;
    user-select: none;
    @extend %secondary-3;
    @extend %flex-justify-between-center;

    @media (max-width: $tablet) {
      padding: 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 19.6px;
    }
  }

  &_item {
    width: 100%;
  }

  &_container {
    overflow: hidden;
    border-bottom: 1px solid $gray-20;
  }

  &_icon {
    transition: all 0.3s;

    &_active {
      transform: rotate(-180deg);
    }
  }
}
