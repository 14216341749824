@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 6px;
  width: 100%;
  position: relative;
  @extend %flex-column;

  &__container {
    width: 100%;
    position: relative;

    &__icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 12px;

      &_filled {
        @include svgFill($gray-700, 24px);
      }
    }
  }

  &__label {
    color: $gray-700;
    @extend %micro-5;

    &__disabled {
      opacity: 0.6;
    }
  }

  &__check {
    top: 50%;
    right: 14px;
    width: 16px;
    height: 16px;
    position: absolute;
    transform: translateY(-50%);

    & > path {
      fill: $success-500;
    }

    &__error > path {
      fill: $error-500;
    }

    &__password {
      right: 42px;
    }
  }

  &__toggle {
    top: 52%;
    right: 14px;
    position: absolute;
    transform: translateY(-50%);
    @include svgFill($gray-700);
  }

  &__input {
    color: $gray-900;
    width: 100%;
    padding: 10px 14px;
    max-height: 44px;
    border-radius: 8px;
    transition: all 0.6s;
    border: 1px solid $gray-300;
    box-shadow: 0px 1px 2px 0px #1018280d;
    @extend %secondary-1;

    &:hover {
      border-color: $gray-700;
    }

    &:disabled,
    &:disabled:hover {
      opacity: 0.6;
      border-color: $gray-300;
    }

    &:focus {
      border-color: $primary-50 !important ;
      box-shadow:
        0px 0px 0px 4px #f7e5e5,
        0px 1px 2px 0px #1018280d;
    }

    &::placeholder {
      color: $gray-500;
    }

    &__error {
      border-color: $error-300 !important;
    }

    &__approved {
      padding-right: 32px !important;
    }

    &__icon {
      padding-left: 42px !important;
    }

    &__password {
      padding-right: 64px !important;
    }
  }

  &__message {
    color: $error-500;
    margin-top: 6px;
    @extend %micro-5;

    &__hint {
      color: $gray-500;
    }
  }
}
