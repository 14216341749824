@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 24px;
  border-radius: 12px;
  margin: 0 80px;
  padding: 12px 22px 12px 12px;
  position: fixed;
  bottom: 100px;
  left: 0;
  z-index: 2;
  width: calc(100% - 160px);
  background: linear-gradient(45deg, #8c0303 0%, #ab0000 100%);
  box-shadow:
    0px 4px 6px -2px #10182808,
    0px 12px 16px -4px #10182814;
  @extend %flex-justify-between-center;

  @media (max-width: $tablet-w) {
    gap: 16px;
    padding: 16px;
    margin: 0 16px;
    flex-direction: column;
    width: calc(100% - 32px);
  }

  &_icon_container {
    min-width: 48px;
    min-height: 48px;
    border-radius: 50%;
    background-color: $primary-500;
    @extend %flex-center;
    @include svgFill($white);
  }

  &_left {
    gap: 16px;
    width: 100%;
    @extend %flex-align;

    &_container {
      @media (max-width: $tablet-w) {
        display: flex;
        gap: 12px;
        @include svgFill($white, 20px);
        @include svgSize(20px, 20px);
      }
    }
  }

  &_title {
    color: $white;
    @extend %secondary-3;

    @media (max-width: $tablet-w) {
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
    }

    &_container {
      gap: 2px;
      @extend %flex-column;

      @media (max-width: $tablet-w) {
        gap: 4px;
      }
    }
  }

  &_subtitle {
    color: $primary-100;
    @extend %secondary-1;

    @media (max-width: $tablet) {
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
    }
  }

  &_button {
    color: $primary-500;
    width: max-content;

    @media (max-width: $mobile-w) {
      width: 100%;
    }
  }

  &_right {
    gap: 18px;
    @extend %flex-align;
    @include svgFill($white, 20px);
  }
}
