@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 16px;
  @extend %flex-align;

  &_left {
    position: relative;
  }

  &_image {
    width: 72px;
    height: 72px;
    border-radius: 8px;
    object-fit: cover;
    @include svgSize(24px, 24px);

    @media (max-width: $tablet) {
      width: 56px;
      height: 56px;
    }
  }

  &_index {
    width: 24px;
    height: 24px;
    color: $white;
    border-radius: 50%;
    position: absolute;
    right: -12px;
    top: -12px;
    background-color: $gray-500;
    border: 1px solid $white;
    @extend %micro-2;
    line-height: 0;
    @extend %flex-center;

    @media (max-width: $tablet) {
      width: 20px;
      height: 20px;
      right: -10px;
      top: -10px;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
    }
  }

  &_right {
    gap: 4px;
    width: 100%;
    @extend %flex-column;

    &_container {
      gap: 8px;
      @extend %flex-justify-between;
    }
  }

  &_title {
    color: $gray-900;
    white-space: nowrap;
    @extend %secondary-3;

    @media (max-width: $tablet) {
      font-weight: 500;
      font-size: 14px;
      line-height: 19.6px;
    }

    &_name {
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (max-width: $tablet) {
        max-width: 180px;
      }
    }
  }

  &_quantity {
    color: $gray-500;
    white-space: nowrap;
    @extend %micro-5;

    @media (max-width: $tablet) {
      font-weight: 400;
      font-size: 12px;
      line-height: 16.8px;
    }
  }
}
