@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 4px;
  position: relative;
  @extend %flex-column;

  &_loader_container {
    width: 100%;
    @extend %flex-center;
  }

  &_flag {
    width: 24px;
    height: 24px;
    user-select: none;
    border-radius: 50%;
    border: 1px solid $gray-200;
  }

  &_input {
    cursor: pointer;
    padding-right: 36px !important;

    &_error {
      padding-right: 64px !important;
    }

    &_lang {
      padding-left: 40px !important;
    }

    &_container {
      gap: 8px;
      width: 100%;
      @extend %flex-align;
      position: relative;
    }

    &_flag {
      width: calc(100% - 24px);
    }

    &_icon {
      position: absolute;
      top: 50%;
      z-index: 1;
      right: 14px;
      transform: translateY(-50%);
      cursor: pointer;
      justify-content: space-between;
      @extend %flex-align;
      @include svgFill($gray-500, 20px);

      &_error {
        right: 36px;
      }
    }
  }

  &_empty {
    padding: 12px 0;
    margin: 12px 0;
    text-align: center;
    border-radius: 8px;
    background-color: $primary-25;
    color: $primary-500;
  }

  &_menu {
    gap: 12px;
    border: 1px solid $gray-200;
    background-color: $white;
    box-shadow: 0px 4px 10px -2px #1018281a;
    border-radius: 10px;
    padding: 12px 14px;
    position: absolute;
    top: 48px;
    left: 0;
    width: 100%;
    z-index: 3;
    @extend %flex-column;

    &_low {
      top: 72px;
    }

    &_container {
      max-height: 235px;
      overflow-y: auto;
      @include scrollbar(8px);
    }

    &_item {
      gap: 8px;
      cursor: pointer;
      width: 100%;
      color: $gray-900;
      padding: 10px 0;
      transition: 0.6s all;
      background-color: $white;
      @extend %secondary-1;
      @extend %flex-justify-between-center;
      @include svgFill($primary-500, 16px);

      &_container {
        gap: 8px;
        @extend %flex-align;
      }

      &:hover,
      &_selected {
        background-color: $gray-50;
      }

      &_container {
        gap: 8px;
        @extend %flex-align;
      }

      &_icon {
        width: 20px;
        height: 20px;

        & > path {
          fill: $primary-500;
        }
      }

      &_prefix {
        padding: 0 8px;
        height: 24px;
        color: $gray-500;
        border-radius: 180px;
        border: 1px solid $gray-300;
        background-color: $white;
        @extend %secondary-1;
        @extend %flex-center;
      }
    }
  }
}
