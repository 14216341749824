@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  width: 100%;
  border-radius: 8px;
  background-color: $white;
  border: 1px solid $gray-200;
  box-shadow:
    0px 1px 2px 0px #1018280f,
    0px 1px 3px 0px #1018281a;
  @extend %flex-center;

  @media (max-width: $tablet) {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
  }

  &_container {
    width: 100%;
    padding: 32px;
    @extend %flex-column-center;
  }

  &_image {
    width: 120px;
    height: 120px;
    object-fit: contain;

    @media (max-width: $tablet) {
      width: 96px;
      height: 96px;
    }
  }

  &_title {
    max-width: 392px;
    color: $gray-900;
    @extend %secondary-3;

    @media (max-width: $tablet) {
      font-weight: 500;
      font-size: 14px;
      line-height: 19.6px;
    }

    &_container {
      gap: 6px;
      margin: 16px 0 24px;
      text-align: center;
      @extend %flex-column-center;
    }
  }

  &_subtitle {
    color: $gray-500;
    @extend %micro-5;
  }
}
