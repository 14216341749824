@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.container {
  &__title {
    margin: 0;
    @extend %micro-5;
    text-transform: capitalize;
  }

  &__description {
    @extend %micro-4;
    color: $white;
    text-transform: capitalize;
  }
}

.success {
  path {
    fill: $success-400;
  }
}

.error {
  path {
    fill: $error-600;
  }
}

.warning {
  path {
    fill: $warning-300;
  }
}

.info {
  path {
    fill: $info;
  }
}

.comming_soon {
  path {
    fill: $warning;
  }
}
