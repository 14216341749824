@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  @extend %flex-column;

  &_image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;

    &_container {
      width: 56px;
      height: 56px;
      border-radius: 50%;
    }

    &_empty {
      background-color: $gray-100;
      @extend %flex-center;

      &_text {
        color: $gray-400;
        @extend %secondary-5;
        line-height: 0;
      }
    }
  }

  &_header {
    gap: 8px;
    padding: 24px;
    cursor: pointer;
    transition: all 0.6s;
    @extend %flex-justify-between;
    @include svgFill($gray-700, 24px);

    &:hover {
      background-color: $gray-200;
    }

    &_container {
      gap: 16px;
      @extend %flex-align;
    }

    &_title {
      color: $gray-900;
      @extend %secondary-5;

      &_container {
        gap: 4px;
        @extend %flex-column;
      }
    }

    &_email {
      color: $gray-500;
      @extend %secondary-1;
    }
  }

  &_divider {
    width: 100%;
    height: 1px;
    background-color: $gray-300;
    margin-bottom: 8px;
  }

  &_item {
    gap: 16px;
    cursor: pointer;
    color: $gray-900;
    padding: 16px 24px;
    transition: all 0.3s;
    @extend %flex-align;
    @extend %secondary-3;
    @include svgFill($primary-500, 24px);

    &:hover {
      background-color: $gray-200;
    }

    &_last {
      margin-bottom: 8px;
    }

    &_warning {
      color: $primary-500;
      padding-bottom: 12px;
      @extend %microSb-5;
    }
  }
}
