@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  max-width: 416px;
  @extend %flex-column-center;

  &_container {
    gap: 8px;
    text-align: center;
    margin: 16px 0 24px;
    @extend %flex-column-center;
  }

  &_title {
    color: $gray-900;
    @extend %secondary-3;
  }

  &_subtitle {
    color: $gray-500;
    @extend %micro-5;
  }

  &_button_container {
    gap: 12px;
    display: flex;
  }
}
