@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 2px;
  padding: 0 12px;
  color: $gray-500;
  cursor: pointer;
  transition: all 0.6s;
  @extend %secondary-1;
  @extend %flex-column-center;
  @include svgFill($gray-500, 24px);

  &:hover {
    color: $gray-900;
    @include svgFill($gray-900, 24px);
  }

  @media (max-width: $tablet-w) {
    @include svgFill($gray-500, 20px);

    &:hover {
      @include svgFill($gray-900, 20px);
    }
  }

  &_active {
    color: $gray-900;
    @include svgFill($primary-500, 24px);

    &:hover {
      @include svgFill($primary-500, 24px);
    }

    @media (max-width: $tablet-w) {
      @include svgFill($primary-500, 20px);

      &:hover {
        @include svgFill($primary-500, 20px);
      }
    }
  }
}
