@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 20px;
  width: 100%;
  display: flex;
  max-width: 803px;

  &_left {
    gap: 24px;
    @extend %flex-column;

    &_image {
      min-width: 197px;
      max-width: 197px;
      height: 236px;
      max-height: 236px;
      object-fit: cover;
      border-radius: 8px;

      @media (max-width: $tablet-l) {
        min-width: 157px;
        max-width: 157px;
        height: 186px;
        max-height: 186px;
      }

      @media (max-width: $tablet) {
        min-width: 56px;
        max-width: 56px;
        height: 72px;
        max-height: 72px;
      }
    }
  }

  &_right {
    width: 100%;
    padding: 14px 0;
    @extend %flex-column;

    &_container {
      height: calc(100% - 32px);
      @extend %flex-column-between;
    }

    &_bottom {
      gap: 12px;
      align-items: flex-end;
      @extend %flex-justify-between;
      @include svgFill($error-500, 24px);
    }
  }

  &_title {
    color: $gray-900;
    white-space: nowrap;
    @extend %subheading-6;

    &_name {
      min-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100vw - 1000px);
    }

    &_container {
      gap: 12px;
      @extend %flex-justify-between-center;
    }
  }

  &_description {
    color: $gray-500;
    @extend %secondary-1;

    &_container {
      gap: 8px;
      padding: 12px 0 24px;
      @extend %flex-column;
    }
  }

  &_quantity {
    height: 56px;
    cursor: pointer;
    text-align: center;
    transition: all 0.6s;
    background-color: $white;
    border: 1px solid $gray-300;
    @extend %micro-3;
    @extend %flex-center;
    @include svgFill($gray-900, 24px);

    &:hover {
      background-color: $gray-300;
    }

    &_container {
      display: flex;
    }

    &_left {
      width: 44px;
      border-radius: 8px 0 0 8px;
    }

    &_center {
      width: 76px;
      border-left: 0;
      border-right: 0;
      user-select: none;

      &:hover {
        background-color: $white;
      }
    }

    &_right {
      width: 44px;
      border-radius: 0 8px 8px 0;
    }
  }
}
