@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  border-radius: 8px;
  border: 1px solid $gray-200;
  @extend %flex-column;

  &_header {
    padding: 16px;
    color: $gray-900;
    background-color: $gray-100;
    @extend %secondary-1;

    @media (max-width: $tablet) {
      font-weight: 400;
      font-size: 14px;
      line-height: 19.6px;
    }
  }

  &_divider {
    width: 100%;
    height: 1px;
    background-color: $gray-200;
  }

  &_title {
    gap: 8px;
    cursor: pointer;
    color: $gray-900;
    @extend %flex-align;
    @extend %secondary-3;
    @include svgFill($primary-500, 20px);

    @media (max-width: $tablet) {
      font-weight: 500;
      font-size: 14px;
      line-height: 19.6px;
    }

    &_container {
      gap: 12px;
      @extend %flex-align;
    }
  }

  &_icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 5.33px solid $primary-25;
    background-color: $primary-50;
    @include svgFill($primary-500, 16px);
    @extend %flex-center;
  }

  &_subtitle {
    color: $gray-500;
    @extend %micro-5;
  }

  &_container {
    gap: 12px;
    padding: 16px;
    @extend %flex-column;
  }
}
