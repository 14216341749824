@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 8px;
  cursor: pointer;
  @extend %flex-align;

  &_container {
    min-width: 44px;
    max-width: 44px;
    height: 24px;
    padding: 0 2px;
    border-radius: 12px;
    transition: all 0.6s;
    background-color: $gray-300;
    @extend %flex-align;

    @media (max-width: $tablet) {
      min-width: 36px;
      max-width: 36px;
      height: 20px;
    }
  }

  &_thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transition: all 0.6s;
    background-color: $white;
    box-shadow:
      0px 1px 2px 0px #1018280f,
      0px 1px 3px 0px #1018281a;

    @media (max-width: $tablet) {
      width: 16px;
      height: 16px;
    }
  }

  &_checked {
    background-color: $primary-500;

    & > .wrapper_thumb {
      transform: translateX(19px);

      @media (max-width: $tablet) {
        transform: translateX(16px);
      }
    }
  }

  &_label {
    color: $gray-900;
    user-select: none;
    @extend %secondary-1;
  }
}
