@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  width: 100%;
  min-width: 305px;
  height: 369px;
  cursor: pointer;

  @media (max-width: $tablet) {
    min-width: 165.5px;
    height: 260px;
  }

  &_header {
    width: 100%;
    border-radius: 8px;

    &_container {
      width: 100%;
      @extend %flex-justify-between;
    }
  }

  &_image {
    width: 100%;
    height: 305px !important;

    @media (max-width: $tablet) {
      height: 208px !important;
    }
  }

  &_bottom {
    gap: 8px;
    padding-top: 12px;
    @extend %flex-column;

    @media (max-width: $tablet) {
      gap: 4px;
      padding-top: 8px;
    }
  }
}
