@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 12px;
  @extend %flex-column;

  &_title {
    color: $gray-900;
    @extend %secondary-3;

    @media (max-width: $tablet) {
      font-weight: 500;
      font-size: 14px;
      line-height: 19.6px;
    }
  }

  &_container {
    gap: 6px;
    display: flex;
    flex-wrap: wrap;
  }
}
