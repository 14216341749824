@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 24px;
  width: 100%;
  @extend %flex-column;

  @media (max-width: $tablet) {
    gap: 16px;
  }

  &_header {
    gap: 16px;
    @extend %flex-justify-between-center;
  }

  &_product {
    max-width: 305px;

    @media (max-width: $tablet) {
      max-width: 165.5px;
    }
  }

  &_title {
    color: $gray-1000;
    @extend %heading-3;

    @media (max-width: $tablet) {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
  }

  &_link {
    gap: 4px;
    color: $primary-500;
    @extend %secondary-1;
    @extend %flex-align;
    @include svgFill($primary-500, 20px);
  }

  &_container {
    gap: 20px;
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    @include scrollbarHorizontal(0);

    @media (max-width: $mobile-w) {
      gap: 12px;
    }
  }

  &_button_container {
    gap: 8px;
    @extend %flex-align;

    & > button {
      padding-inline: 0;
      width: 48px;
    }
  }
}
