@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 12px;
  width: 100%;
  @extend %flex-column;

  &_header {
    gap: 8px;
    cursor: pointer;
    @include svgFill($gray-500, 24px);
    @extend %flex-justify-between-center;
  }

  &_title {
    gap: 8px;
    color: $gray-500;
    @extend %micro-5;
    @extend %flex-align;
    @include svgFill($primary-500, 20px);
  }

  &_icon {
    transition: all 0.6s;

    &_active {
      transform: rotate(180deg);
    }
  }

  &_container {
    gap: 12px;
    @extend %flex-column;
  }

  &_item {
    gap: 8px;
    @extend %flex-column;

    &_title {
      color: $gray-700;
      @extend %microSb-5;
    }

    &_text {
      color: $gray-500;
      @extend %micro-5;
    }

    &_container {
      gap: 16px;
      @extend %flex-align;
    }

    &_box {
      gap: 8px;
      @extend %flex-column;
    }

    &_icon {
      min-width: 32px;
      min-height: 32px;
      max-width: 32px;
      max-height: 32px;
      border-radius: 50%;
      background-color: $gray-300;
      @extend %flex-center;
      @include svgFill($gray-500, 20px);
    }

    &_line {
      width: 2px;
      height: 12px;
      margin-left: 14px;
      background-color: $gray-15;
    }
  }

  &_agree_terms {
    color: $gray-500;
    @extend %micro-5;
  }

  &_policy {
    color: $gray-900;
    cursor: pointer;
    text-decoration: underline;
  }
}
