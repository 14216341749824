@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 16px;
  @extend %flex-align;

  &_left {
    position: relative;
  }

  &_image {
    width: 72px;
    height: 72px;
    border-radius: 8px;
    object-fit: cover;
    @include svgSize(24px, 24px);

    @media (max-width: $tablet) {
      width: 56px !important;
      height: 56px !important;
    }
  }

  &_right {
    gap: 4px;
    width: 100%;
    @extend %flex-column;

    &_container {
      gap: 8px;
      @extend %flex-justify-between;
    }
  }
}
