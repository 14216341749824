@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 12px;
  @extend %flex-justify-between-center;

  &_left {
    gap: 8px;
    @extend %flex-align;
  }

  &_text {
    color: $gray-700;
    @extend %microSb-5;
  }

  &_right {
    gap: 12px;
    @extend %flex-align;

    & > button {
      max-height: 36px;

      @media (max-width: $tablet) {
        max-width: 36px;
        padding-inline: 12px;
      }
    }
  }

  &_select {
    max-width: 80px;
    max-height: 36px;
  }
}
