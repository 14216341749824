@use 'styles/vars.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 12px;
  position: relative;
  @extend %flex-column;

  &_percent {
    color: $gray-900;
    background-color: $gray-200;
    padding: 2px 6px;
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: -28px;
    transition: all 0.6s;
    @extend %micro-2;
  }

  &_background {
    width: 100%;
    height: 8px;
    border-radius: 12px;
    background-color: $gray-100;
  }

  &_fill {
    width: 0;
    height: 100%;
    transition: 0.6s all;
    border-radius: 12px;
    background-color: $primary-500;
  }
}
