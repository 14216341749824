@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  width: 100%;
  height: max-content;
  border-radius: 8px;
  background-color: $white;
  border: 1px solid $gray-300;
  @extend %flex-column;

  &_container {
    gap: 16px;
    width: 100%;
    padding: 24px;
    @extend %flex-column;
  }

  &_row {
    gap: 12px;
    width: 100%;
    @extend %flex-justify-between-center;
  }

  &_column {
    gap: 8px;
    @extend %flex-column;
  }

  &_bottom {
    gap: 12px;
    width: 100%;
    padding: 16px 24px;
    border-top: 1px solid $gray-300;
    @extend %flex-column;
  }

  &_item {
    &_container {
      gap: 12px;
      width: 100%;
      display: flex;
      border-radius: 8px;
    }
  }
}
