@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  background-color: $white;
  width: 100%;
  padding: 12px 16px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 2;
  border-top: 1px solid $gray-300;
  @extend %flex-center;

  &_tab {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
  }
}
