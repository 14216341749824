@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 24px;
  width: 100%;
  @extend %flex-column-align;

  &_container {
    gap: 20px;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    &_single {
      display: flex;
    }
  }

  &_swiper {
    width: 100%;
    max-width: 768px;
  }

  &_image {
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
    object-position: center;
    height: 370px;

    @media (max-width: $tablet) {
      height: 432px;
    }

    &_single {
      height: 760px;
      border-radius: 8px;
      @include svgSize(348px, 348px);

      @media (max-width: $tablet) {
        height: 432px;
      }
    }
  }
}
