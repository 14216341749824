@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 64px;
  width: 100%;
  height: 100%;
  padding: 24px;
  border-radius: 8px;
  background-color: $white;
  @extend %flex-column-between;

  @media (max-width: $tablet-m) {
    gap: 16px;
    padding: 16px;
    flex-direction: row;
    justify-content: flex-start;
  }

  &_icon {
    min-width: 48px;
    min-height: 48px;
    max-height: 48px;
    max-width: 48px;
    border-radius: 8px;
    background-color: $primary-500;
    @extend %flex-center;
    @include svgFill($white);

    @media (max-width: $tablet-m) {
      min-width: 40px;
      min-height: 40px;
      max-height: 40px;
      max-width: 40px;
      @include svgFill($white, 20px);
    }
  }

  &_container {
    gap: 8px;
    @extend %flex-column;
  }

  &_title {
    color: $gray-900;
    @extend %subheading-2;

    @media (max-width: $tablet-m) {
      font-size: 16px;
      font-weight: 500;
      line-height: 22.4px;
    }
  }

  &_subtitle {
    color: $gray-500;
    @extend %secondary-1;

    @media (max-width: $tablet-m) {
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
    }
  }
}
