@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 32px;
  padding: 24px;
  overflow-y: auto;
  height: calc(100dvh - 77px);
  @extend %flex-column;

  @media (max-width: $tablet) {
    padding: 32px 16px;
  }

  &_cabinet {
    padding: 0;
  }

  &_container {
    gap: 16px;
    @extend %flex-column;

    &_center {
      gap: 16px;
      @extend %flex-column-center;
    }
  }

  &_title {
    color: $gray-900;
    @extend %subheading-5;

    @media (max-width: $tablet) {
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;
    }
  }

  &_subtitle {
    color: $gray-400;
    @extend %secondary-1;

    @media (max-width: $tablet) {
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
    }
  }

  &_form {
    gap: 32px;
    @extend %flex-column;
  }

  &_button_container {
    gap: 12px;
    @extend %flex-column;
  }

  &_agree_terms {
    text-align: center;
    color: $gray-500;
    @extend %secondary-1;
  }

  &_policy {
    color: $gray-900;
    cursor: pointer;
    text-decoration: underline;
  }

  &_icon {
    width: 72px;
    height: 72px;
    background-color: $gray-100;
    border-radius: 50%;
    @extend %flex-center;
    @include svgFill($gray-500, 32px);

    @media (max-width: $tablet) {
      width: 56px;
      height: 56px;
      @include svgFill($gray-500, 24px);
    }
  }

  &_footer {
    gap: 4px;
    @extend %flex-column-center;

    &_text {
      color: $gray-500;
      @extend %secondary-1;
    }

    &_link {
      cursor: pointer;
      color: $primary-500;
      @extend %secondary-3;
    }
  }

  &_button {
    max-height: 46px;

    @media (max-width: $tablet) {
      max-height: 44px;
    }
  }

  &__input {
    @include inputStyles();
    width: 100% !important;
    height: 64px;
    max-width: 64px;
    max-height: 64px;
    @extend %flex-center;

    @media (max-width: $tablet) {
      height: 56px;
    }
  }
}
