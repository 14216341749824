@use 'styles/vars' as *;
@use 'styles/colors' as *;
@use 'styles/mixins' as *;
@use 'styles/typography' as *;

.wrapper {
  width: 100%;
  gap: 64px;
  margin: 0 auto;
  padding: 24px 80px 96px;
  @extend %flex-justify-between;

  @media (max-width: $tablet) {
    gap: 24px;
    flex-direction: column;
    align-items: center;
    padding: 24px 0 48px;
  }

  &_image {
    @media (max-width: $tablet-l) {
      height: 432px !important;
    }
  }

  &__container {
    gap: 12px;
    @extend %flex-column;
  }

  &__delivery {
    gap: 16px;
    @extend %flex-column;

    &__subtitle {
      gap: 10px;
      color: $black-300;
      @extend %flex-align;
      @extend %micro-5;
    }
  }

  &__right {
    gap: 32px;
    width: 100%;
    @extend %flex-column;

    @media (max-width: $tablet) {
      gap: 24px;
    }

    &__buttons {
      gap: 10px;
      margin-top: 22px;
      @extend %flex-column;
    }
  }

  &__container {
    &__description {
      gap: 24px;
      @extend %flex-column;

      &__title {
        color: $black-600;
        @extend %micro-3;
        font-weight: 400;
      }

      &__text {
        color: $black-300;
        @extend %secondary-1;
        margin-top: 12px;
        margin-bottom: 24px;
      }
    }

    &__top {
      gap: 40px;
      display: flex;

      @media (max-width: 1260px) {
        gap: 0px;
      }
    }
  }

  &__button_container {
    gap: 10px;
    @extend %flex-column;
  }

  &__notify {
    gap: 12px;
    @extend %flex-align;
  }

  &__store {
    gap: 8px;
    display: flex;
    flex-wrap: wrap;

    &__title_container {
      gap: 12px;
      display: flex;
      justify-content: space-between;
    }

    &__title {
      color: $black;
      @extend %micro-3;
    }

    &__subtitle {
      color: $red;
      @extend %secondary-1;
    }
  }
}

.progress {
  gap: 12px;
  position: relative;
  @extend %flex-column;

  &__percent {
    color: #fff;
    background-color: #000;
    padding: 2px;
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: -32px;
    transition: all 0.6s;
  }

  &__background {
    width: 100%;
    height: 10px;
    background-color: #cacaca;
    border-radius: 12px;
  }

  &__fill {
    width: 0;
    height: 100%;
    transition: 0.6s all;
    background-color: #000;
    border-radius: 12px;
  }
}
