@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 24px;
  padding-top: 24px;
  @extend %flex-column;

  @media (max-width: $tablet) {
    gap: 16px;
    padding-top: 16px;
  }

  &_details {
    gap: 8px;
    padding-top: 24px;
    @extend %flex-column;
    color: $gray-900;
    @extend %microSb-5;

    &:first-letter {
      text-transform: uppercase;
    }

    @media (max-width: $tablet) {
      padding-top: 16px;
    }
  }

  &_container {
    gap: 12px;
    @extend %flex-column;
  }

  &_title {
    color: $gray-900;
    @extend %subheading-3;

    @media (max-width: $tablet) {
      font-weight: 500;
      font-size: 14px;
      line-height: 19.6px;
    }

    &_container {
      gap: 4px;
      @extend %flex-column;
    }
  }

  &_subtitle {
    color: $gray-900;
    @extend %microSb-5;
  }

  &_description {
    color: $gray-500;
    @extend %micro-5;
  }
}
