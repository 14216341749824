@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  width: 100%;
  gap: 24px;
  max-width: 1440px;
  margin: 0 auto;
  @extend %flex-justify-between;

  @media (max-width: $wide) {
    flex-direction: column;
    align-items: center;
  }

  &__container {
    gap: 12px;
    @extend %flex-column;
  }

  &__delivery {
    gap: 16px;
    @extend %flex-column;

    &__subtitle {
      gap: 10px;
      color: $black-300;
      @extend %flex-align;
      @extend %micro-5;
    }
  }

  &__right {
    gap: 32px;
    width: 100%;
    max-width: 580px;
    @extend %flex-column;

    &__buttons {
      gap: 10px;
      margin-top: 22px;
      @extend %flex-column;
    }

    &__title {
      color: $black-600;
      word-break: break-word;
      @extend %heading-5;

      @media (max-width: $tablet) {
        font-size: 26px;
        font-weight: 600;
      }
    }

    &__code {
      color: $black-400;
      @extend %secondary-1;

      @media (max-width: $tablet) {
        font-size: 14px;
        font-weight: 400;
      }
    }

    &__price {
      color: $red;
      @extend %heading-3;

      @media (max-width: $tablet) {
        font-size: 30px;
        font-weight: 700;
      }

      &__recommended {
        color: $black-300;
        border: 1px solid #95959580;
        @extend %micro-3;
        font-weight: 400;
        padding: 4px 8px;
        border-radius: 2px;
        max-width: max-content;
      }

      &__old {
        color: $black-300;
        @extend %subheading-4;
        text-decoration: line-through;

        @media (max-width: $tablet) {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  &__link {
    color: $red;
    gap: 10px;
    @include svgFill($red, 10px);
    display: flex;
    align-items: center;
    @extend %secondary-1;
  }

  &__container {
    &__description {
      gap: 24px;
      @extend %flex-column;

      &__title {
        color: $black-600;
        @extend %micro-3;
        font-weight: 400;
      }

      &__text {
        color: $black-300;
        @extend %secondary-1;
        margin-top: 12px;
        margin-bottom: 24px;
      }
    }

    &__top {
      gap: 40px;
      display: flex;

      @media (max-width: 1260px) {
        gap: 0px;
      }
    }
  }

  &__button_container {
    gap: 10px;
    @extend %flex-column;
  }

  &__notify {
    gap: 12px;
    @extend %flex-align;
  }

  &__store {
    gap: 8px;
    display: flex;
    flex-wrap: wrap;

    &__title_container {
      gap: 12px;
      display: flex;
      justify-content: space-between;
    }

    &__title {
      color: $black;
      @extend %micro-3;
    }

    &__subtitle {
      color: $red;
      @extend %secondary-1;
    }
  }
}

.progress {
  gap: 12px;
  position: relative;
  @extend %flex-column;

  &__percent {
    color: #fff;
    background-color: #000;
    padding: 2px;
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: -32px;
    transition: all 0.6s;
  }

  &__background {
    width: 100%;
    height: 10px;
    background-color: #cacaca;
    border-radius: 12px;
  }

  &__fill {
    width: 0;
    height: 100%;
    transition: 0.6s all;
    background-color: #000;
    border-radius: 12px;
  }
}
