@use 'styles/vars.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 12px;
  cursor: pointer;
  width: max-content;
  @extend %flex-align;

  &_label {
    color: $gray-900;
    user-select: none;
    @extend %secondary-1;
  }
}
