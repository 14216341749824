@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  width: 100%;
  @extend %flex-column;

  &__tabs {
    width: 100%;
    cursor: pointer;
    overflow-x: auto;
    position: relative;
    scroll-behavior: smooth;
    @extend %flex-align;
    @include scrollbar(0);
    @include scrollbarHorizontal(0);

    &__tab {
      gap: 8px;
      white-space: nowrap;
      color: $gray-900;
      @extend %flex-align;
      @extend %secondary-1;
      @include svgFill($gray-900, 24px);

      @media (max-width: $tablet) {
        font-weight: 400;
        font-size: 14px;
        line-height: 19.6px;
      }

      &__active {
        font-weight: 500;
      }

      &__count {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: $gray-900;
        background-color: $gray-100;
        @extend %microSb-5;
        @extend %flex-center;
      }

      &__container {
        gap: 8px;
        padding: 16px 16px 13px;
        @extend %flex-align;

        &__active {
          color: $primary-500;
        }
      }
    }
  }

  &__line {
    left: 0;
    z-index: 1;
    width: 100%;
    height: 3px;
    bottom: 0;
    position: absolute;
    border-radius: 2px;
    background-color: $gray-900;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &__divider {
    width: 100%;
    height: 1px;
    margin-top: -2px;
    background-color: $gray-300;
  }
}
