@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  width: 100%;
  @extend %flex-align;

  &_input {
    width: 100%;
    padding-left: 42px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    @media (max-width: $tablet-l) {
      max-height: 40px !important;
    }
  }

  &_button {
    width: max-content;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    @media (max-width: $tablet-l) {
      padding: 9px 8px;
    }
  }

  &_icon {
    top: 50%;
    left: 12px;
    width: 24px;
    height: 24px;
    position: absolute;
    transform: translateY(-50%);
  }
}
