@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  width: 100%;
  @extend %flex-column;

  &_header {
    width: 100%;
    color: $white;
    padding: 10px 80px;
    background-color: $gray-900;
    @extend %flex-center;
    @extend %secondary-1;
    text-align: center;

    @media (max-width: $wide) {
      padding: 10px 24px;
    }

    @media (max-width: $tablet-m) {
      padding: 10px 16px;
      font-size: 12px;
      font-weight: 400;
      line-height: 16.8px;
    }
  }

  &_logo {
    @media (max-width: $tablet-l) {
      width: 100px;
    }
  }

  &_nav {
    width: 100%;
    gap: 32px;
    padding: 6px 80px;
    background-color: $white;
    transition: all 0.6s;
    border-bottom: 1px solid $gray-200;
    @extend %flex-align;

    @media (max-width: $wide) {
      padding: 12px 24px;
    }

    @media (max-width: $tablet-m) {
      padding: 12px 16px;
    }
  }

  &_center {
    gap: 16px;
    width: 100%;
    @extend %flex-align;
  }

  &_right {
    gap: 9px;
    @extend %flex-align;
  }

  &_tab {
    padding: 6px 16px;

    @media (max-width: $tablet-l) {
      padding: 6px 8px;
    }

    &:hover,
    &_active {
      border-radius: 8px;
      background-color: $gray-100;
      color: $gray-900;
      @include svgFill($gray-900, 24px);
    }
  }

  &_button {
    border-radius: 8px;
    background-color: $gray-100;
    gap: 8px;
    padding: 10px 16px;
    color: $gray-900;
    @extend %secondary-1;
    @extend %flex-align;
    @include svgFill($gray-700, 24px);
  }
}
