@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  @extend %flex-column;

  &_container {
    overflow-y: auto;
    height: calc(100dvh - 152px);
    @include scrollbar(0);

    &_header {
      gap: 16px;
      padding: 24px;
      overflow: hidden;
      border-bottom: 1px solid $gray-200;
      @extend %flex-column;

      @media (max-width: $tablet) {
        padding: 16px;
      }

      &_title {
        color: $gray-900;
        @extend %secondary-3;

        @media (max-width: $tablet) {
          font-weight: 500;
          font-size: 14px;
          line-height: 19.6px;
        }
      }

      &_badge {
        gap: 8px;
        color: $gray-900;
        padding: 5px 10px;
        white-space: nowrap;
        max-height: 32px;
        cursor: pointer;
        border-radius: 6px;
        background-color: $gray-100;
        @extend %flex-align;
        @extend %secondary-1;
        @include svgFill($gray-900, 16px);

        @media (max-width: $tablet) {
          font-weight: 400;
          font-size: 14px;
          line-height: 19.6px;
          max-height: 30px;
        }

        &_container {
          gap: 8px;
          flex-wrap: wrap;
          @extend %flex-align;
        }
      }
    }
  }

  &_accordion {
    padding: 0 24px;

    @media (max-width: $tablet) {
      padding: 0 16px;
    }

    &_item {
      width: 100%;
      padding: 8px 0;
      user-select: none;
      cursor: pointer;
    }
  }

  &_bottom {
    padding: 16px 24px;
    border-top: 1px solid $gray-200;
  }

  &_sort_by {
    cursor: pointer;
    color: $gray-1000;
    padding: 10px 24px;
    transition: all 0.3s;
    border-left: 2px solid transparent;
    @extend %micro-5;

    @media (max-width: $tablet) {
      padding: 10px 16px;
    }

    &:hover,
    &_active {
      border-left-color: $gray-1000;
    }
  }
}
