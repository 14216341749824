@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 8px;
  flex-wrap: wrap;
  @extend %flex-align;
  @include svgFill($primary-500, 24px);

  &_item {
    color: $gray-700;
    cursor: pointer;
    transition: all 0.6s;
    white-space: nowrap;
    border-bottom: 1px solid transparent;
    @extend %secondary-1;

    &:hover,
    &_active {
      color: $gray-900;
      border-color: $gray-900;
    }
  }
}
