@use 'styles/vars.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'styles/colors.scss' as *;
@use 'styles/typography.scss' as *;

.wrapper {
  gap: 32px;
  width: 100%;
  max-width: 413px;
  @extend %flex-column;

  @media (max-width: $tablet-l) {
    gap: 24px;
    max-width: unset;
  }

  @media (max-width: $tablet) {
    overflow: hidden;
  }

  &_head {
    gap: 116px;
    cursor: pointer;
    padding: 16px;
    background-color: $gray-5;
    border-top: 1px solid $gray-200;
    border-bottom: 1px solid $gray-200;
    @extend %flex-justify-between-center;

    &_title {
      gap: 2px;
      color: $primary-500;
      @extend %flex-align;
      @include svgFill($primary-500, 16px);

      > svg {
        transition: all 0.6s;
      }

      &_active > svg {
        transform: rotate(-180deg);
      }
    }

    &_total {
      color: $gray-900;
      @extend %micro-3;
    }
  }

  &_menu {
    gap: 32px;
    width: 100%;
    @extend %flex-column;

    @media (max-width: $tablet-l) {
      gap: 24px;
      padding: 16px;
      border-bottom: 1px solid $gray-200;
    }
  }

  &_error {
    color: $primary-500;
    @extend %micro-2;
  }

  &_row {
    gap: 24px;
    display: flex;

    @media (max-width: $tablet) {
      gap: 16px;
      flex-direction: column;
    }

    &_between {
      gap: 12px;
      @extend %flex-justify-between;
    }
  }

  &_line {
    width: 100%;
    height: 1px;
    background-color: $gray-300;
  }

  &_container {
    gap: 16px;
    @extend %flex-column;

    @media (max-width: $tablet) {
      gap: 12px;
    }
  }

  &_text {
    color: $gray-900;
    @extend %secondary-1;

    @media (max-width: $tablet) {
      font-weight: 400;
      font-size: 14px;
      line-height: 19.6px;
    }
  }

  &_price {
    color: $gray-900;
    @extend %secondary-3;

    @media (max-width: $tablet) {
      font-weight: 500;
      font-size: 14px;
      line-height: 19.6px;
    }
  }

  &_total {
    color: $primary-500;
    @extend %micro-3;

    @media (max-width: $tablet) {
      font-weight: 600;
      font-size: 14px;
      line-height: 19.6px;
    }
  }
}
